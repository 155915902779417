@font-face {
  font-family: SegoeUI;
  src: url('./assets/SegoeUI.ttf');
  font-display: swap;
}

@font-face {
  font-family: SegoeUIBold;
  src: url('./assets/SegoeUIBold.ttf');
  font-display: swap;
}

body {
  background-color: #000;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #000;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 200px;
  background-size: cover;
  background-position-x: center;
}

.text {
  color: #fff;
  font-family: 'SegoeUIBold';
  font-size: 64px;
  text-transform: uppercase;
  letter-spacing: 4.3px;
  font-weight: bold;
  z-index: 1;
  margin-top: 32px;
  text-align: center;
}

.countdown {
  color: #fff;
  font-family: 'SegoeUI';
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
  z-index: 1;
  margin-top: 32px;
  text-align: center;
}
